import React from 'react'
import NavBar from '../../components/NavBar'
import LandingPage from '../LandingPage/LandingPage'
import OurBillers from '../LandingPage/OurBillers'
import Services from '../LandingPage/Services'
import Testimonials from '../LandingPage/Testimonials'
import Growth from '../LandingPage/Growth'
import Footer from '../LandingPage/Footer'
import ForgotDialogue from './ForgotDialogue'

const ForgotPassword = () => {
    return (
        <div className="w-[100vw] overflow-clip flex flex-col items-center">
            <NavBar />
            <ForgotDialogue />
            <Footer />
        </div>
    )
}

export default ForgotPassword