import { Backdrop, Snackbar, SnackbarCloseReason } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import LoadingButton from '@mui/lab/LoadingButton';
import { FcCancel } from "react-icons/fc";
import { FaCircleCheck } from "react-icons/fa6";
import { useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

const ForgotDialogue = () => {
    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)
    const [showDialogue, setShowDialogue] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState<boolean | null>(null)
    const [message, setMessage] = useState("")
    const [resendLoading, setResendLoading] = useState(false)
    const [showResendDialog, setShowResendDialog] = useState(false)
    const [resendError, setResendError] = useState(false)
    const [resendMessage, setResendMessage] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()
    const [countdown, setCountdown] = useState(0); // For the countdown timer
    const passwordRef = useRef<HTMLInputElement>(null)
    const confirmPasswordRef = useRef<HTMLInputElement>(null)

    let email = searchParams.get("email") ?? ""
    let token = searchParams.get("token") ?? ""
    const baseUrl = "https://api.connectsphere.com.ng"

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowResendDialog(false);
    };

    // Countdown logic
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    async function sendResetEmail() {
        try {
            setResendLoading(true)
            const response = await axios.post(`${baseUrl}/auth/reset-password/resend`, {
                email
            })
            setResendMessage("Resent email has been sent! Check you mail.")
            setResendLoading(false)
            setShowResendDialog(true)
            setCountdown(60); // Start the 1-minute countdown after sending the email
        } catch (err) {
            const error = err as AxiosError
            setResendLoading(false)

            setResendMessage((error?.response?.data as any)?.message ?? "An error occured")
        }
    }
    async function submitResetPassword() {
        const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/
        console.log(!regexPattern.test(passwordRef.current?.value ?? ""))
        if (!regexPattern.test(passwordRef.current?.value ?? "")) {
            setMessage("Password must be at least 6 characters and it must contain at least: An Upper case, a lower case and a number.")
            setHasError(true)
            setShowDialogue(true)
            return;
        }
        if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
            setMessage("Password and Confirm password does not match")
            setHasError(true)
            setShowDialogue(true)
            return;
        }
        try {
            setIsLoading(true)
            const response = await axios.post(`${baseUrl}/auth/reset-password/submit`, {
                email, password: passwordRef.current?.value, token
            })
            passwordRef!.current!.value = ""

            confirmPasswordRef!.current!.value = ""
            setIsLoading(false)
            setHasError(false)
            setShowDialogue(true)

        } catch (err) {
            const error = err as AxiosError
            setIsLoading(false)
            setMessage((error?.response?.data as any).message)
            setHasError(true)
            setShowDialogue(true)
        }

    }

    return (
        <div className=' h-[96vh] py-[120px] flex flex-col items-center'>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={showResendDialog}
                onClose={handleClose}
                autoHideDuration={6000}
                message={resendMessage}
                key="{vertical + horizontal}"
            />

            {showDialogue && <Backdrop open={showDialogue}
                sx={(theme) => ({ zIndex: 50 })}>
                {hasError ? <div className='w-[280px] md:w-[450px] h-[300px] bg-white rounded-xl flex flex-col items-center p-3 md:p-5 justify-evenly'>
                    <FcCancel className='text-[70px] text-red-500' />
                    <p className='text-black text-center text-sm'>{message}</p>
                    <p className='bg-[#ffd233] px-4 py-3 rounded-xl text-center cursor-pointer' onClick={(e) => {
                        setIsLoading(false)
                        setHasError(false)
                        setShowDialogue(false)
                    }}>Close</p>
                </div> : <div className='w-[280px] md:w-[450px] h-[300px] bg-white rounded-xl flex flex-col items-center p-3 md:p-5 justify-evenly'>
                    <FaCircleCheck className='text-[70px] text-green-500' />
                    <p>Password Successfully changed.</p>
                    <p className='bg-[#ffd233] px-4 py-3 rounded-xl text-center cursor-pointer' onClick={(e) => {
                        setShowDialogue(false)
                    }}>Proceed to login in the app</p>
                </div>}

            </Backdrop>}
            <p className='text-[20px] font-bold'>Reset Account Password</p>
            <p className='text-[14px] text-center'>Enter a new password for <span className='font-bold text-[#d40000]'>{email}</span></p>
            <div className='h-[40px]'></div>
            <div className='my-[10px] flex flex-col items-center'>
                <p className='font-bold text-sm'>New Password</p>
                <div className='p-2 border-[1px] rounded-lg overflow-clip md:w-[400px] w-[250px] my-[5px] flex'>
                    <input ref={passwordRef} type={showPassword ? 'text' : "password"} placeholder='Enter your new password' className='w-full border-none outline-none focus:outline-none' />
                    <div onClick={(e) => {
                        setShowPassword(value => !value)
                    }}>
                        {!showPassword ? <MdOutlineVisibility className='text-[25px]' /> : <MdOutlineVisibilityOff className='text-[25px]' />}
                    </div>
                </div>
            </div>
            <div className='my-[10px] flex flex-col items-center'>
                <p className='font-bold text-sm'>Confirm Password</p>
                <div className='p-2 border-[1px] rounded-lg overflow-clip md:w-[400px] w-[250px] my-[5px] flex'>
                    <input ref={confirmPasswordRef} type={showConfirmPassword ? 'text' : "password"} placeholder='Confirm password' className='w-full border-none outline-none focus:outline-none' />
                    <div onClick={(e) => {
                        setShowConfirmPassword(value => !value)
                    }}>
                        {!showConfirmPassword ? <MdOutlineVisibility className='text-[25px]' /> : <MdOutlineVisibilityOff className='text-[25px]' />}
                    </div>
                </div>
            </div>
            <div className='h-[60px]'></div>
            {!isLoading ? <p className='md:w-[400px] cursor-pointer w-[250px] py-[12px] rounded-lg bg-[#d40000] text-white text-center' onClick={async (e) => {
                await submitResetPassword()
            }} >Submit</p> : <LoadingButton loading
                loadingPosition="center"
                className='md:w-[400px] w-[250px] h-[100px]'
                variant="outlined">
            </LoadingButton>
            }

            {/* Display the countdown if active */}
            {!resendLoading ? (
                countdown === 0 ? (
                    <p className='underline text-[#d40000] my-2 text-xm cursor-pointer' onClick={(e) => sendResetEmail()}>
                        Resend reset email
                    </p>
                ) : (
                    <p className=' text-black/75 my-2 text-xm cursor-pointer'>
                        Resend email in {countdown}s
                    </p>
                )
            ) : (
                <p className=' text-black/75 my-2 text-xm cursor-pointer'>Sending reset email</p>
            )}
        </div>
    )
}

export default ForgotDialogue;
